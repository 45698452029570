import {measuringUnits} from "../../../helpers/articleHelpers";
import hexRgb from "hex-rgb";
import React from "react";

const UnitAgreement = ({measuringUnit}) => {
    const color = measuringUnits.hasOwnProperty(measuringUnit) ?
        measuringUnits[measuringUnit].color : '#fff'
    const opacity = measuringUnits.hasOwnProperty(measuringUnit) ?
        measuringUnits[measuringUnit].opacity : '0.3'
    return (
        <div className={'text-black rounded px-2 py-1 mr-2'} style={{backgroundColor: hexRgb(color, {alpha: opacity, format: 'css'})}}>
            {measuringUnit}
        </div>
    )
}
export default UnitAgreement;