import axios from "axios";
import {toast} from "react-toastify";
import { t } from './helpers';

axios.interceptors.request.use(req => {
    // `req` is the Axios request config, so you can modify
    // the `headers`.
    req.headers.authorization = 'Bearer ' + localStorage.getItem('jwtToken');
    req.headers.common['Content-Type'] =
        'application/json';
    return req;
});

axios.interceptors.response.use(function (response) {
    if(response?.data?.success === false && response?.data?.message && response?.data?.notification){
        toast.error(t(response?.data?.message));
    }
    if(response?.data?.success === true && response?.data?.message && response?.data?.notification){
        toast.success(t(response?.data?.message));
    }
    return response;
}, function (error) {

    switch(error.response.status){
        case 401:
            toast("Je bent niet meer ingelogd.")
            window.location = "/login";
            break;
        default:
            return Promise.reject(error);

    }

});

export function kokoFetch( url, method = 'get', data = {}){

    return axios({
        method,
        url,
        data
    });

}
