import React from 'react'
import {Card, CardBody} from "@windmill/react-ui";
import {SpinnerDotted} from "spinners-react";
import {default as LoaderSpinner} from "react-loader-spinner";

const Loader = ({ simple = false}) =>
{
    if( simple ){
        return (
            <LoaderSpinner type="Bars" color="#24262d" height={30} width={30} />

        )
    }

    return (
        <Card className="my-4 shadow-md">
            <CardBody>
                <LoaderSpinner type="Bars" color="#24262d" height={30} width={30} />
            </CardBody>
        </Card>

    )
}

export default Loader;