import React, { useState, useMemo } from 'react'

// create context
export const SearchContext = React.createContext()

export const SearchProvider = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState('')


  const value = useMemo(
    () => ({
        searchQuery,
        setSearchQuery,
    }),
    [searchQuery]
  )

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
}
