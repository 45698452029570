import React from 'react'
import Loader from './KokoComponents/Loader';

function ThemedSuspense() {
  return (
    <div className="w-full h-screen p-6 text-lg font-medium text-gray-600 dark:text-gray-400 dark:bg-gray-900 flex items-center justify-center">
        <Loader simple={true}/>
    </div>
  )
}

export default ThemedSuspense
