import {typesAgreement} from "../../../helpers/articleHelpers";
import hexRgb from "hex-rgb";
import React from "react";

const TypeAgreement = ({typeAgreement}) => {
    const color = typesAgreement.hasOwnProperty(typeAgreement) ?
        typesAgreement[typeAgreement].color : '#fff'
    const opacity = typesAgreement.hasOwnProperty(typeAgreement) ?
        typesAgreement[typeAgreement].opacity : '0.3'
    return (
        <div className={'text-black rounded px-2 py-1 mr-2'} style={{backgroundColor: hexRgb(color, {alpha: opacity, format: 'css'})}}>
            {typeAgreement}
        </div>
    )
}
export default TypeAgreement;