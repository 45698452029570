import React, {useContext, useEffect, useState} from 'react'
import PageTitle from '../components/Typography/PageTitle'

import {useParams} from "react-router-dom";
import {useLoadProjectQuery} from "../hooks/useLoadProjectQuery";
import {FigmaArrowLeft} from "../icons";
import InfoIcon from '@mui/icons-material/Info';
import ArticleIcon from '@mui/icons-material/Article';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CalculateIcon from '@mui/icons-material/Calculate';

function ProjectDetails() {

    let {project, projectView} = useParams();

    const {
        isLoading: projectIsLoading,
        isFetching: projectIsFetching,
        error: projectError,
        data: projectData
    } = useLoadProjectQuery(project);

    return (
        <>
            <div className="bg-white">

                <div className={'bg-primary w-full'} style={{
                    height: "30vh",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: `linear-gradient(0deg, rgba(104,52,215,1) 0%, rgba(104,52,215,0.3575805322128851) 100%), url(${projectData?.details?.files?.cover[0]?.fullPath})`,

                }}>
                    <div className="flex flex-col h-full" style={{'padding': '50px 30px  30px 30px'}}>
                        <FigmaArrowLeft className={'relative inline-block'}/>
                        <div className="text-white mt-auto font-bold text-3xl">
                            {projectData?.label}
                        </div>
                    </div>

                </div>
                <div className="w-full mt-4 mb-12 grid grid-cols-4 gap-4 text-center text-grey">
                    <div className="col-span-1 text-primary">
                        <InfoIcon className={'mb-1'}/>
                        <br/>
                        Info
                    </div>
                    <div className="col-span-1">
                        <ArticleIcon className={'mb-1'}/>
                        <br/>
                        Lastenboek
                    </div>
                    <div className="col-span-1">
                        <FormatListBulletedIcon className={'mb-1'}/>
                        <br/>
                        Meetstaat
                    </div>
                    <div className="col-span-1">
                        <CalculateIcon className={'mb-1'}/>
                        <br/>
                        Prijzen
                    </div>
                </div>
                <div className="text-lg w-full" style={{'padding': '0 20px 0 20px'}}>
                    {projectData?.details?.description}

                    <div className="text-2xl font-bold text-primary-dark mt-8">
                        Plannen
                    </div>
                </div>
                <div className="w-full">

                </div>
                {console.log(projectData)}
            </div>

        </>
    )
}

export default ProjectDetails