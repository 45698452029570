import {useQuery} from "react-query";
import {loadProject} from "../helpers/projectHelpers";

export const useLoadProjectQuery = (id, section = null, texts = false) => {
    // en dan instellen via store active op basis van project
    // die cache items mogen blijven bestaan

    return useQuery('project_' + id, () => loadProject({id, section, texts}), {
        initialData: {
            id: 0,
            label: null,
            articles: []
        }
    })
}