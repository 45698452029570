import React, {lazy, useEffect} from 'react'
import {BrowserRouter as Router, Switch, Route, Redirect, useLocation} from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {useStoreActions} from "easy-peasy";
import {ToastContainer, toast} from 'react-toastify';
import './assets/scss/App.scss';

import 'react-toastify/dist/ReactToastify.css';
import {kokoFetch} from "./helpers/fetchHelpers";
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
import {createTheme, ThemeProvider} from "@material-ui/core";
import ProjectDetails from "./pages/ProjectDetails";

const theme = createTheme({
    palette: {
        primary: {
            main: "#7E3AF2"
        },
        secondary: {
            main: "#7E3AF2"
        }
    }
});


const Layout = lazy(() => import('./containers/Layout'))
const AppSwitcher = lazy(() => import('./containers/AppSwitcher'))
const Login = lazy(() => import('./pages/Login'))
const LoginLinkCheck = lazy(() => import('./pages/LoginLinkCheck'))
const ChangeFirstTimePassword = lazy(() => import('./pages/ChangeFirstTimePassword'))


const CreateAccount = lazy(() => import('./pages/CreateAccount'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const queryClient = new QueryClient()


function App() {

    return (
        <>
            <script src="https://cdn.tailwindcss.com"/>
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <div className={'bg-light-peach'}>
                        <Router >
                            <AccessibleNavigationAnnouncer/>
                            <Switch>
                                <Route path="/login" component={Login}/>
                                <Route path="/create-account" component={CreateAccount}/>
                                <Route path="/forgot-password" component={ForgotPassword}/>
                                <Route path="/login-link-check" component={LoginLinkCheck}/>
                                <Route path="/change-first-time-password" component={ChangeFirstTimePassword}/>

                                {/*TODO: Sander - move to routes/index.js. This is just here for demo purpose.*/}
                                {/*
                                <Route path="/app/project-details/:project?" component={ProjectDetails}/>
                                */}

                                {/* Place new routes over this */}
                                <Route path="/app" component={Layout}/>
                                {/* If you have an index page, you can remothis Redirect */}
                                <Redirect exact from="/" to="/login"/>
                            </Switch>



                        </Router>
                    </div>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme={'light'}
                    />
                    <ReactQueryDevtools initialIsOpen={false}/>

                </QueryClientProvider>
            </ThemeProvider>
        </>
    )
}

export default App
