import React from 'react';
import translations from '../translations/translations';


export function t(string = '', lang = 'nl', params = {}) {
    if (
        string &&
        translations.hasOwnProperty(string) &&
        translations[string].hasOwnProperty(lang) &&
        translations[string][lang] !== ''
    ) {
        let processedString = translations[string][lang];
        if (Object.keys(params).length !== 0 && params.constructor === Object) {
            Object.keys(params).forEach(function(key) {
                processedString = processedString.replace(`[${key}]`, params[key]);
            });
            return processedString;
        }
        return translations[string][lang];
    }
    return string;
}

export function bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return 'n/a'
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
    if (i === 0) return `${bytes} ${sizes[i]})`
    return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
}

export const mapboxApiKey = 'pk.eyJ1Ijoiam9za29rbyIsImEiOiJja2k0bHR2c2sxbGYxMnltc2tuMWJyMGZyIn0.M1ZKrdr6UGuQ5PgAA6_jZA';
