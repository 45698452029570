// miscchien dynamisch met php opbouwen zodat vanuit backend geladen kan worden?
import Tab from "@mui/material/Tab";
import {t} from "../helpers/helpers";
import Tabs from "@mui/material/Tabs";
import React from "react";

const translations = {
    name: {
        nl: 'Naam',
        en: 'Name'
    },
    save: {
        nl: 'Bewaren',
        en: 'Save'
    },
    extra: {
        nl: "Extra info",
        en: "Extra info"
    },
    bus: {
        nl: 'Bus (optioneel)',
        en: 'Bus (optional)'
    },
    project_name: {
        nl: 'Geef je project een naam',
        en: 'Provide the project with a name',
    },
    project_location_permission: {
        nl: 'Iedereen met toegang tot het project kan de locatie zien.',
        en: 'Everyone with access to the project can view the location.'
    },
    project_location: {
        nl: 'Wat is de locatie van het project?',
        en: 'What is the location of this project?'
    },
    error_minlength_10: {
        nl: 'De minimale lengte is 10 tekens',
        en: 'The minlength is 10 digits'
    },
    error_maxlength_60: {
        nl: 'Maximum lengte is 60 tekens',
        en: 'The maxlength is 60 digits'
    },
    project_text: {
        nl: 'Maak duidelijk aan anderen waarover dit project gaat!',
        en: 'Make clear to other what this project is about!'
    },
    extra_info: {
        nl: "Geef wat extra context om het volledige plaatje te kunnen schetsen.",
        en: "Provide some extra context to sketch the complete package"
    },
    completed: {
        nl: 'Afgerond',
        en: 'Completed'
    },
    plans: {
        nl: 'Plannen',
        en: 'Plans'
    },
    images: {
        nl: 'Afbeeldingen',
        en: 'Images'
    },
    files: {
        nl: 'Documenten',
        en: 'Documents'
    },
    rennovation_or_newbuilt: {
        nl: "Renovatie of nieuwbouw?",
        en: "Rennovation or newbuilt?"
    },
    rennovation: {
        nl: "Renovatie",
        en: "Rennovation"
    },
    newbuilt: {
        nl: "Nieuwbouw",
        en: "Newbuilt"
    },
    contacts: {
        nl: 'Klanten',
        en: 'Clients'
    },
    save_success: {
        nl: 'Bewaren gelukt',
        en: 'Saving was a succes'
    },
    drag_and_drop: {
        nl: 'Sleep je bestanden hierheen / Klik hier om een bestand op te laden',
        en: 'Drag and drop files here'
    },
    drag_and_drop_single: {
        nl: 'Sleep het bestand hierheen / Klik hier om een bestand op te laden',
        en: 'Drag and drop file here'
    },
    uploading: {
        nl: 'Bezig met opladen...',
        en: 'Uploading...'
    },
    upload_files: {
        nl: 'Bestanden opladen',
        en: 'Upload files'
    },
    upload_file: {
        nl: 'Bestand opladen',
        en: 'Upload file'
    },
    selected_filters: {
        nl: 'Geselecteerde filters',
        en: 'Selected filters'
    },
    dashboard_projects: {
        nl: 'Projecten',
        en: 'Library'
    },
    filters: {
        nl: 'Filters',
        en: 'Filters'
    },
    search_contact: {
        nl: 'Een klant zoeken',
        en: 'Search a client'
    },
    search_contractor: {
        nl: 'Een aannemer zoeken',
        en: 'Search a contractor'
    },
    search_organisation: {
        nl: 'Een bedrijf zoeken',
        en: 'Search a company'
    },
    setting: {
        nl: 'Type',
        en: 'Type'
    },
    add_file: {
        nl: 'Een bestand toevoegen',
        en: 'Add a file'
    },
    add_setting: {
        nl: 'Een type toevoegen',
        en: 'Add a type'
    },
    edit_setting: {
        nl: 'Een type aanpassen',
        en: 'Edit a type'
    },
    add_address: {
        nl: 'Een adres toevoegen',
        en: 'Add an address'
    },
    edit_address: {
        nl: 'Een adres aanpassen',
        en: 'Edit an address'
    },
    add_news: {
        nl: 'Een nieuwsbericht toevoegen',
        en: 'Add a newsitem'
    },
    edit_news: {
        nl: 'Een nieuwsbericht aanpassen',
        en: 'Edit a newsitem'
    },
    add_contact: {
        nl: 'Een klant toevoegen',
        en: 'Add a client'
    },
    edit_contact: {
        nl: 'Een klant aanpassen',
        en: 'Edit a client'
    },
    file: {
        nl: 'Bestand',
        en: 'File'
    }
    ,add_contractor: {
        nl: 'Een aannemer toevoegen',
        en: 'Add a contractor'
    },
    edit_contractor: {
        nl: 'Een aannemer aanpassen',
        en: 'Edit a contractor'
    },
    contractors: {
        nl: 'Aannemers',
        en: 'Contractors'
    },
    contact: {
        nl: 'Klant',
        en: 'Client'
    },
    organisation: {
        nl: 'Bedrijf',
        en: 'Company'
    },
    organisations: {
        nl: 'Bedrijven',
        en: 'Companies'
    },
    add_organisation: {
        nl: 'Een bedrijf toevoegen',
        en: 'Add a company'
    },
    edit_organisation: {
        nl: 'Een bedrijf aanpassen',
        en: 'Edit a company'
    },
    zipcode: {
        nl: 'Postcode',
        en: 'Zipcode'
    },
    zipcode_city: {
        nl: 'Postcode / Stad',
        en: 'Zipcode / City'
    },
    city: {
        nl: 'Stad',
        en: 'City'
    },
    street: { 
        nl: 'Straat',
        en: 'Street'
    },
    your_pricing: {
        nl: 'Je eigen raming',
        en: 'Your own pricing'
    },
    automatic_pricing: {
        nl: 'Automatische raming',
        en: 'Automatic pricing'
    },
    create_template: {
        nl: 'Maak een template van dit project',
        en: 'Create a template from this project'
    },
    contact_title: {
        nl: 'Klant',
        en: 'Client'
    },
    title: {
        nl: 'Titel',
        en: 'Title'
    },
    news_title: {
        nl: 'Nieuwsbericht',
        en: 'Newsitem'
    },
    edit_article: {
        nl: 'Artikel bewerken',
        en: 'Edit article'
    },
    article_add_intro: {
        nl: 'Kies hieronder een bestaand artikel waaronder het nieuw artikel zal vallen',
        en: 'Choose an existing article below where the new article will be added under'
    },
    avatar: {
        nl: 'Foto',
        en: 'Photo'
    }, main_image: {
        nl: 'Hoofdafbeelding',
        en: 'Main image'
    },
    article_parent: {
        nl: 'Koppel hoofdartikel',
        en: 'Link headarticle'
    },
    article_title: {
        nl: 'Nieuw artikel',
        en: 'New article'
    },
    contact_intro: {
        nl: 'Gegevens',
        en: 'Data'
    },
    profile_title: {
        nl: 'Profiel',
        en: 'Profile'
    },
    profile_intro: {
        nl: 'Bewerk hieronder jouw profiel',
        en: 'Edit your profile below'
    },
    news_intro: {
        nl: 'Gegevens',
        en: 'Data'
    },
    organisation_title: {
        nl: 'Bedrijf',
        en: 'Company'
    },
    organisation_intro: {
        nl: 'Gegevens',
        en: 'Data'
    },
    required: {
        nl: 'verplicht',
        en: 'required'
    },
    invalid_number_format: {
        nl: 'Nummer verkeerde formaat',
        en: 'Invalid number format'
    },
    invalid_email_format: {
        nl: 'E-mail staat in het verkeerde formaat',
        en: 'Invalid email format'
    },
    invalid_website_format: {
        nl: 'Website staat in het verkeerde formaat',
        en: 'Invalid website format'
    },
    settings: {
        nl: 'Instellingen',
        en: 'Settings'
    },
    settings_title: {
        nl: 'Instellingen',
        en: 'Settings'
    },
    settings_intro: {
        nl: 'Beheer je instellingen',
        en: 'Manage settings'
    },
    blanco: {
        nl: 'Blanco',
        en: 'Blanco'
    },
    blanco_info: {
        nl: 'Begin vanaf een wit blad',
        en: 'Start from a blank page'
    },
    import: {
        nl: 'Opladen',
        en: 'Import'
    },
    import_info: {
        nl: 'Gebruik een eigen excel',
        en: 'Import your own excel '
    },
    back: {
        nl: 'Ga terug',
        en: 'Go back'
    },
    article_number: {
        nl: 'Nummer',
        en: 'Number'
    },
    add_article: {
        nl: 'Nieuw artikel toevoegen',
        en: 'Add new article'
    },
    this_is_me: {
        nl: 'Dit ben ik',
        en: 'This is me'
    },
    email_linked_to_user: {
        nl: 'Email kan niet gewijzigd worden omdat dit contact aan een gebruiker gekoppeld is.',
        en: 'Email can not be changed because this contact is linked to a user.'
    },
    add_first_articles: {
        nl: 'Voeg eerste artikels toe aan dit project',
        en: 'Add first articles to project'
    },
    no_articles_added: {
        nl: 'Nog geen artikels toegevoegd',
        en: 'No articles added yet'
    },
    add_selected_articles: {
        nl: 'Geselecteerde artikels toevoegen',
        en: 'Add selected articles'
    },
    enable_auto_numbering_default: {
        nl: 'Automatische nummering standaard aanzetten voor projecten',
        en: 'Enable automatic numbering for all projects'
    },
    project_wizard: {
        nl: 'Nieuw project',
        en: 'New project'
    },
    template: {
        nl: 'Template',
        en: 'Template'
    },
    library: {
        nl: 'Bibliotheek',
        en: 'Library'
    },
    news: {
        nl: 'Nieuws',
        en: 'News'
    },
    dashboard: {
        nl: 'Dashboard',
        en: 'Dashboard'
    },
    advanced_settings: {
        nl: 'Geavanceerde instellingen',
        en: 'Advanced settings'
    },
    contacts_and_organisations: {
        nl: 'Contacten',
        en: 'Contacts'
    },
    template_info: {
        nl: 'Start van een vooringevulde meetstaat',
        en: 'Start from a predefinded template'
    },
    project_wizard_intro: {
        nl: 'Je kan op verschillende manieren een project toevoegen',
        en: 'You can add a project in different ways'
    },
    firstname: {
        nl: 'Voornaam',
        en: 'Firstname'
    },
    your_projects: {
        nl: 'Jouw projecten',
        en: 'Your projects'
    },
    lastname: {
        nl: 'Achternaam',
        en: 'Lastname'
    },
    email: {
        nl: 'E-mail',
        en: 'Email'
    },
    company: {
        nl: 'Bedrijf',
        en: 'Company'
    },
    vat_number: {
        nl: 'BTW-nummer',
        en: 'VAT number'
    },
    main_settings: {
        nl: 'Instellingen',
        en: 'Settings'
    },
    file_settinggs: {
        nl: 'Bestandstypes',
        en: 'File types'
    },
    contact_settings: {
        nl: 'Contacttypes',
        en: 'Contact types'
    },
    organisation_settings: {
        nl: 'Organisatietypes',
        en: 'Organisation types'
    },
    logo: {
        nl: 'Logo',
        en: 'Logo'
    },
    housenr: {
        nl: 'Huisnr.',
        en: 'Housenr.'
    },
    phone: {
        nl: 'Telefoon',
        en: 'Phone'
    },
    mobile: {
        nl: 'GSM',
        en: 'Mobile'
    },
    website: {
        nl: 'Website',
        en: 'Website'
    },
    information: {
        nl: 'Info',
        en: 'Info'
    },
    address: {
        nl: 'Adres',
        en: 'Address'
    },
    type: {
        nl: 'Type',
        en: 'Type'
    },
    subtype: {
        nl: 'Subtype',
        en: 'Subtype'
    },
    firstname_partner: {
        nl: 'Voornaam partner',
        en: 'Firstname partner'
    },
    lastname_partner: {
        nl: 'Achternaam partner',
        en: 'Lastname partner'
    },
    national_insurance_number: {
        nl: 'Nationaal verzekeringsnummer',
        en: 'National Insurance Number'
    },
    language: {
        nl: 'Taal',
        en: 'Language',
        fr: 'Langue'
    },
    table: {
        nl: 'Tabel',
        en: 'Table',
        fr: 'Table'
    },
    bill_of_measurement: {
        nl: 'Meetstaat',
        en: 'bill_of_measurement',
    },
    specifications: {
        nl: 'Bestek',
        en: 'specification',
    },
    description: {
        nl: 'Omschrijving',
        en: 'description',
    },
    materials: {
        nl: 'Materialen',
        en: 'materials',
    },
    project_info: {
        nl: 'Projectinfo',
        en: 'Project info',
    },
    prices: {
        nl: 'Prijzen',
        en: 'Prices',
    },
    execution: {
        nl: 'Uitvoering',
        en: 'execution',
    },
    measurement: {
        nl: 'Meting',
        en: 'measurement',
    },
    examination: {
        nl: 'Keuring',
        en: 'examination',
    },
    FH: {
        nl: 'Forfaitaire Hoeveelheid',
    },
    VH: {
        nl: 'Vermoedelijke Hoeveelheid',

    },
    SOG: {
        nl: 'Som Over Geheel',

    },
    PM: {
        nl: 'Pro Memorie',

    },
    TP: {
        nl: 'Totaalprijs',

    },
    TVS: {
        nl: 'Te Verrekenen Som',

    },
    text_changed: {
        nl: 'Deze tekst werd gewijzigd maar nog niet bewaard, wil je zeker doorgaan?',
    },
    measurement_view: {
        nl: 'Meting'
    },
    text_view: {
        nl: 'Teksten'
    },
    close_modal: {
        nl: "Sluit venster"
    },
    return_to_list: {
        nl: "Terug naar lijst",
        en: "Backto list"
    },
    add: {
        nl: "Toevoegen",
        en: "Add"
    },
    edit: {
        nl: "Aanpassen",
        en: "Edit"
    },
    problem_with_article: {
        nl: "Er is een probleem met dit artikel. Kan het artikel niet laden.",
        en: "There is a problem with this article. Could not load article."
    },
    m3: {
        nl: 'm³',
        en: 'm³'
    },
    m2: {
        nl: 'm²',
        en: 'm²'
    },
    m: {
        nl: 'm',
        en: 'm'
    },
    st: {
        nl: 'stuk',
        en: 'st'
    },
    ton: {
        nl: 'ton',
        en: 'ton'
    },
    kW: {
        nl: 'kW',
        en: 'kW'
    },
    trap: {
        nl: 'trap',
    },
    trede: {
        nl: 'trede',
    },
    length: {
        nl: 'lengte',
        en: 'length'
    },
    number: {
        nl: 'aantal',
        en: 'number'
    },
    suggested_type: {
        nl: 'Aanbevolen type',
        en: 'Suggested type'
    },
    default_type: {
        nl: 'Alle types',
        en: 'All types'
    },
    suggested_unit: {
        nl: 'Aanbevolen meeteenheid',
        en: 'Suggested measuring unit'
    },
    default_unit: {
        nl: 'Alle meeteenheden',
        en: 'All units'
    },
    export_specifications: {
        nl: 'Pdf exporteren',
        en: 'Export pdf'
    },
    add_text: {
        nl: 'Tekst toevoegen',
        en: 'Add text'
    },
    offer_request_contact_combination_exists : {
        nl: 'Er is reeds een offertevraag uitgestuurd naar dit contact voor dit project.',
        en: 'An offer request has already been sent to this contact for this project.'
    },
    user_with_email_exists : {
        nl: 'Het contact dat je geselecteerd hebt heeft een email adres dat reeds met een andere gebruiker gekoppeld is.',
        en: 'The contact you selected has an email which is already coupled to another user'
    },
    project_not_valid : {
        nl: 'Geen geldig project',
        en: 'Project not valid.'
    },
    default_types : {
        nl: 'Typelastenboek',
        en: 'Default types'
    },
    prices_types: {
        nl: 'Overzicht prijzen',
        en: 'Overview prices'
    },
    notification_phone : {
        nl: 'Telefoonnr voor notificaties',
        en: 'Phone for notifications'
    },
    notification_email : {
        nl: 'Email voor notificaties',
        en: 'Email for notifications'
    },
    notification_email_limit : {
        nl: 'Email notificatie limiet',
        en: 'Email notification limit'
    },
    notification_phone_limit : {
        nl: 'Telefoon notificatie limiet',
        en: 'Phone notification limit'
    },
    type_text_new: {
        nl: 'Nieuw type toevoegen',
        en: 'Add new type'
    },
    label_type_text_new: {
        nl: 'Voeg een nieuw teksttype toe',
        en: 'Add a new text type'
    },
    available_tokens: {
        nl: 'Kopieer en plak deze tokens in je tekst, ze worden vervangen door de waardes die je ingeeft bij projectinfo.',
        en: 'Available tokens'
    },
    articles: {
        nl: 'Artikels',
        en: 'Articles'
    }
};

export default translations;
