import {FaListUl, FaRegListAlt} from "react-icons/fa";
import {IoDocumentText} from "react-icons/io5";
import {RiFolderInfoLine} from "react-icons/ri";
import {AiFillCalculator} from "react-icons/ai";
import React from "react";
import {kokoFetch} from "./fetchHelpers";
import {buildArticleTree, processArticles} from "./articleHelpers";
import {toast} from "react-toastify";


export function saveProjectArticle() {
    return kokoFetch(process.env.REACT_APP_API_URL + '/api/article').then(res => {
            const processedArticles = processArticles(res.data);
            const articlesTree = buildArticleTree(processedArticles);
            return {
                articles: processedArticles,
                articlesTree
            };
        }
    )
}

export const deleteProjectArticles = (listArticlesToDelete) => {
    if(listArticlesToDelete.length === 0){
        return false;
    }
    return kokoFetch(`${process.env.REACT_APP_API_URL}/api/delete-project-articles`, 'POST',
        {listArticlesToDelete})
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            toast.error(`Er ging iets mis`)
        })
}


export function loadProject({id, section, texts}) {
    if (!id) {
        return false;
    }
    return kokoFetch(`${process.env.REACT_APP_API_URL}/api/project/${id}`, 'POST', {id, section, texts})
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            toast.error(`Er ging iets mis`)
        })
}

export function loadProjectTextsSection({id, section}) {
    if (!id) {
        return false;
    }
    return kokoFetch(`${process.env.REACT_APP_API_URL}/api/project-texts-section/${id}`, 'POST', {id, section})
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            toast.error(`Er ging iets mis`)
        })
}





export function loadProjectArticlesTree(id) {
    if (!id) {
        return false;
    }
    return kokoFetch(`${process.env.REACT_APP_API_URL}/api/project/${id}/articles-tree`)
        .then((response) => {
            return response.data;
            console.log(response.data)
            return response.data;
        })
        .catch(() => {
            toast.error(`Er ging iets mis`)
        })
}

export function loadProjectArticles(id){

    if (!id) {
        return false;
    }
    return kokoFetch(`${process.env.REACT_APP_API_URL}/api/project/${id}/articles`)
        .then((response) => {
            return response.data;
            console.log(response.data)
            return response.data;
        })
        .catch(() => {
            toast.error(`Er ging iets mis`)
        })
}

export function addProjectArticle({project, article, amount, texts}) {
    //return kokoFetch(`${process.env.REACT_APP_API_URL}/api/project/article/add`)
    return kokoFetch(`${process.env.REACT_APP_API_URL}/api/project/article/${project}/${article}`, 'PUT', {
        amount,
        texts
    })
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            toast.error(`Er ging iets mis`)
        })
}

export function addProjectArticles(project, articles, callback = () => {}) {
    //return kokoFetch(`${process.env.REACT_APP_API_URL}/api/project/article/add`)
    return kokoFetch(`${process.env.REACT_APP_API_URL}/api/project/articles/add/${project}`, 'POST', {articles})
        .then((response) => {
            callback();
            return response.data;
        })
        .catch(() => {
            toast.error(`Er ging iets mis`)
        })
}

export function updateProjectArticle({projectArticleId, amount, texts}) {
    //return kokoFetch(`${process.env.REACT_APP_API_URL}/api/project/article/add`)
    return kokoFetch(`${process.env.REACT_APP_API_URL}/api/project/article/update/${projectArticleId}`, 'POST', {
        texts
    })
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            toast.error(`Er ging iets mis`)
        })
}

export function updateProject({id}) {
    const data = {};
    return kokoFetch(`${process.env.REACT_APP_API_URL}/api/project/update/${id}`,
        'POST', data)
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            toast.error(`Er ging iets mis`)
        })
}


export function deleteProjectArticle({projectArticle}) {
    //return kokoFetch(`${process.env.REACT_APP_API_URL}/api/project/article/add`)
    return kokoFetch(`${process.env.REACT_APP_API_URL}/api/project/article/${projectArticle}`, 'DELETE')
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            toast.error(`Er ging iets mis`)
        })
}


export function changeProjectLabel({label, id}) {

    if (label && id) {
        return kokoFetch(`${process.env.REACT_APP_API_URL}/api/project/${id}/label`, 'POST', {label})
            .then((response) => {
                return response.data;
            })
            .catch(() => {
                toast.error(`Er ging iets mis`)
            })
    }

}

export const navigateToLastView = (lastActiveView, history) => {
    if(lastActiveView.project && lastActiveView !== 0 && lastActiveView?.projectView){
        let url = `/app/project/${lastActiveView?.project}/${lastActiveView?.projectView}?section=${lastActiveView?.section}&projectArticle=${lastActiveView?.projectArticle}?#article_${lastActiveView?.number}`;
        history.push(url)
    } else {
        history.goBack();
    }
}

export function saveProjectDetails(payload) {
    console.log(payload)
    // console.log("payload", payload)
    return kokoFetch(`${process.env.REACT_APP_API_URL}/api/project/update/${payload.id}`, 'POST', {
        ...payload
    })
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            toast.error(`Er ging iets mis`)
        })
}


const link = '/app/project/[ID]';
export const projectIcons = {
    project_info: {
        icon: <RiFolderInfoLine/>,
        articleView: true,
        link: link + "/project_info"
    },
    specifications: {
        icon: <IoDocumentText/>,
        articleView: true,
        link: link + "/specifications"
    },
    prices: {
        icon: <AiFillCalculator/>,
        articleView: true,
        link: link + "/prices"
    }
}

export const emptyLastActiveView = {

    projectView: "specifications",
        project: 0,
    projectArticle: 0,
    section: 0,
    number: 0
}


/*export const projectIcons = {
    project_info: {
        icon: <RiFolderInfoLine/>,
        articleView: true,
        link: link + "/project_info"
    },
    specifications: {
        icon: <IoDocumentText/>,
        articleView: true,
        link: link + "/specifications"
    },
    bill_of_measurement: {
        icon: <FaListUl/>,
        articleView: true,
        link: link + "/bill_of_measurement"
    },
    prices: {
        icon: <AiFillCalculator/>,
        articleView: true,
        link: link + "/prices"
    }
}*/
